$primary: #ff5b24 !default;
$primary-100: #ffefe9 !default;
$primary-200: #ffe1d7 !default;
$primary-300: #ffc3af !default;
$primary-400: #ffa587 !default;
$primary-500: #ff8860 !default;
$primary-600: #ff6a38 !default;
$primary-700: #ff5b24 !default;
$primary-800: #ba421a !default;
$primary-900: #8b3214 !default;
@import '@skiwo/styles/global/variables.scss';

.toast {
  .body {
    display: flex;
    justify-content: space-between;
    gap: 1rem;

    .content {
      display: flex;
      gap: 0.5rem;

      .success {
        color: $success-600;
        font-size: 1rem;
        padding: 0.125rem;
      }

      .error {
        color: $error-600;
        font-size: 1rem;
        padding: 0.125rem;
      }

      .message {
        font-size: 0.875rem;
        line-height: 1.25rem;
        font-weight: 400;
      }
    }

    .button {
      background: none;
      color: inherit;
      border: none;
      padding: 0;
      font: inherit;
      cursor: pointer;
      outline: inherit;
    }

    .closeButton {
      color: $secondary-200;
    }
  }
}
